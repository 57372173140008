// Here you can add other styles
.node__root > circle {
    fill: green;
}
  
.node__branch > circle {
    fill: #607d8b;
}
  
.node__leaf > circle {
    fill: #673ab7;
    /* Let's also make the radius of leaf nodes larger */
}
